import React, { Component } from "react";

class Hero extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    render() {
        return (
            <section className="hero is-primary has-text-white">
                <div className="hero-body">
                    <p className="title has-text-white">
                        {this.props.title}
                    </p>
                    <p className="subtitle has-text-white">
                        {this.props.subtitle}
                    </p>
                </div>
            </section>
        )
    }
}

export default Hero;

import React, { Component } from "react";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

// import { WithContext as ReactTags, SEPARATORS } from "react-tag-input";

import ImageUploader from 'react-image-upload'
import 'react-image-upload/dist/index.css'

// import keyword_extractor from 'keyword-extractor';

class App extends Component {
    constructor(props) {
        super(props);

        this.artistChanged = this.artistChanged.bind(this);
        // this.addArtist = this.addArtist.bind(this);
        // this.addLocation = this.addLocation.bind(this);
        // this.deleteArtist = this.deleteArtist.bind(this);
        this.getExistingPaintings = this.getExistingPaintings.bind(this);
        this.addTag = this.addTag.bind(this);

        // this.handleDrag = this.handleDrag.bind(this);
        // this.handleDelete = this.handleDelete.bind(this);
        // this.onTagUpdate = this.onTagUpdate.bind(this);
        // this.onClearAll = this.onClearAll.bind(this);


        this.state = {
            newArtist: true,
            newLocation: true,
            status: 'writing',
            existingPaintings: [],
            errors: null,
            imageError: false,
            tags: [
            ],
            existingTags: [],
        }

    }

    componentDidMount() {
        let serverUrl = 'http://localhost:8080/'
        if (process.env.NODE_ENV === 'development') serverUrl = 'http://localhost:8080/'
        else if (process.env.NODE_ENV === 'production') serverUrl = window.location.protocol + '//' + window.location.host + '/'
        this.setState({ serverUrl: serverUrl }, this.getData)
    }

    getData() {
        this.getArtists();
        this.getExistingTags();
        this.getLocations();
    }
    getArtists() {
        // const params = new URLSearchParams({})
        const options = {
            method: 'GET'
        }
        fetch(this.state.serverUrl + 'artists', options)
            .then((response) => response.json())
            .then((json) => {
                // let artists = [];
                // for (let artist in json) {
                //     if (json[artist].fullname != '') {
                //         artists.push(json[artist].artist)
                //     }
                // }
                this.setState({ artists: json }, console.log(this.state.artists))
                console.log(json)
            })
            .catch(err => console.log(err));
    }


    getExistingTags() {
        // const params = new URLSearchParams({})
        const options = {
            method: 'GET'
        }
        fetch(this.state.serverUrl + 'tags', options)
            .then((response) => response.json())
            .then((json) => {
                let tags = [];
                for (let tag of json) {
                    tags.push({ id: tag.id, text: tag.tag })
                }
                this.setState({ existingTags: tags })
            })
            .catch(err => console.log(err));
    }


    getTags() {
        // const imageUrl = 'https://imagga.com/static/images/tagging/wind-farm-538576_640.jpg';

        // const options = {
        //     method: 'GET',
        //     username: 'acc_3bd462d89b6b725',
        //     password: 'da66551e93405eccf4d5600e4eac45b6'

        // }
        // fetch('https://api.imagga.com/v2/tags?image_url=' + encodeURIComponent(imageUrl), options)
        //     .then((response) => response.json())
        //     .then((json) => {
        //         console.log(json)
        //     })
        //     .catch(err => console.log(err));

        // console.log(image)
        // var reader = new window.FileReader();
        // reader.readAsDataURL(image);
        // reader.onloadend = function () {
        //     const base64data = reader.result;
        //     console.log(base64data);


        //     const values = {
        //         image_base64: base64data
        //     }
        //     const keys = 'Basic YWNjXzNiZDQ2MmQ4OWI2YjcyNTpkYTY2NTUxZTkzNDA1ZWNjZjRkNTYwMGU0ZWFjNDViNg=='
        //     const options = {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //             'Authorization': keys,
        //         },
        //         body: JSON.stringify(values)
        //     }

        //     fetch('https://api.imagga.com/v2/tags', options)
        //         .then(res => res.json().then(json => {
        //             console.log(json)
        //         }));
        // }
    }
    // getImageFileObject(imageFile) {
    //     this.getTags(imageFile)

    //     console.log({ imageFile })
    // }

    // runAfterImageDelete(file) {
    //     console.log({ file })
    // }
    getLocations() {
        // const params = new URLSearchParams({})
        const options = {
            method: 'GET'
        }
        fetch(this.state.serverUrl + 'location', options)
            .then((response) => response.json())
            .then((json) => {
                let locations = [];
                for (let location in json) {
                    if (json[location].location != '') {
                        locations.push(json[location].location)
                    }
                }
                this.setState({ locations: locations })
            })
            .catch(err => console.log(err));
    }


    artistChanged() {
        if (parseInt(document.getElementById('artist').value) === 0) {
            this.setState({ newArtist: true });
        } else {
            this.setState({ newArtist: false });
        }
    }
    locationChanged() {
        if (parseInt(document.getElementById('location').value) === 0) {
            this.setState({ newLocation: true });
        } else {
            this.setState({ newLocation: false });
        }
    }

    // addArtist() {

    //     const artist = document.getElementById('artist').value;

    //     let artistExist = false;
    //     let artistAlreadySelected = false;




    //     for (let selCat of this.state.selectedArtists) {
    //         if (selCat.name === artist) {
    //             artistAlreadySelected = true;
    //             document.getElementById('artist').value = null;
    //         }
    //     }

    //     if (!artistAlreadySelected) {
    //         for (let art of this.state.artists) {
    //             if (artist === art.fullname) {
    //                 artistExist = true;
    //                 this.setState({ selectedArtists: [...this.state.selectedArtists, { id: art.id, name: art.name }] })
    //                 document.getElementById('artist').value = null;
    //                 break;
    //             }
    //         }
    //         if (!artistExist) {
    //             // Add a new artist

    //             const values = {
    //                 artist: artist
    //             }
    //             const options = {
    //                 method: 'POST',
    //                 headers: {
    //                     'Content-Type': 'application/json'
    //                 },
    //                 body: JSON.stringify(values)
    //             }

    //             fetch(this.state.serverUrl + 'c', options)
    //                 .then(res => res.json().then(json => {
    //                     if (json.message === 'success') {

    //                         this.setState({ artists: [...this.state.artists, { id: json.id, name: artist }] },
    //                             this.addArtist);


    //                     }
    //                 }));
    //         }
    //     }
    // }
    addLocation() {

        const location = document.getElementById('location').value;
        let locationExist = false;

        for (let cat of this.state.locations) {
            if (location === cat.name) {
                locationExist = true;
                break;
            }
        }
        if (!locationExist) {
            // Add a new location

            const values = {
                location: location
            }
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(values)
            }

            fetch(this.state.serverUrl + 'c', options)
                .then(res => res.json().then(json => {
                    if (json.message === 'success') {

                        this.setState({ locations: [...this.state.locations, { id: json.id, name: location }] },
                            this.addLocaiton);
                    }
                }));

        }
    }


    imageChanged(image) {

        let imageName = image.name;
        this.setState({ imageName: imageName });

        let ext = imageName.split('.')[1];
        if (ext !== 'jpg' && ext !== 'jpeg' && ext !== 'png' && ext !== 'gif' && ext !== 'webp') {
            this.setState({ imageError: true })
        } else {
            this.setState({ imageError: false })
        }
        this.getTags(image);
    }

    getExistingPaintings() {

        // TODO : Check if painting already in collection

        // const sentence = document.getElementById('question').value;
        // this.setState({ existingQuestions: [] })

        // var extraction_result = keyword_extractor.extract(sentence, {
        //     language: 'french',
        //     remove_digits: true,
        //     return_changed_case: true,
        //     remove_duplicates: true
        // });

        // let resultsString = '';
        // for (let result of extraction_result) {
        //     resultsString += result + ',';
        // }

        // if (resultsString.length > 0) resultsString = resultsString.substring(0, resultsString.length - 1);

        // if (extraction_result.length > 0) {
        //     const params = new URLSearchParams({ k: resultsString })
        //     const options = {
        //         method: 'GET'
        //     }

        //     fetch(this.state.serverUrl + 'q?' + params, options)
        //         .then((response) => response.json())
        //         .then((json) => {
        //             this.setState({ existingQuestions: json })
        //         })
        //         .catch(err => console.log(err));
        // }
    }




    ////////// TAG MANAGEMENT //////////////
    addTag() {
        let tag = document.getElementById('tag').value;
        tag = tag.trim();

        if (tag == '' || tag == undefined) return;

        let tagObject = {
            text: tag
        }

        let tagExist = false;
        // let tagAlreadySelected = false;

        console.log(this.state.tags)
        // Check if tag already in current tag list
        if (Object.keys(this.state.tags).length != 0 && this.state.tags != undefined) {
            for (let i of this.state.tags) {
                if (i.text == tag) return;
            }
        }



        // Check if tag already in existing tags
        for (let j of this.state.existingTags) {
            if (j.text == tag) {
                tagObject = j;
                tagExist = true;
            };
        }

        console.log(tagObject);

        if (!tagExist) {
            // Ajouter à database
            const values = {
                tag: tagObject.text
            }
            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(values)
            }

            fetch(this.state.serverUrl + 'addTag', options)
                .then(res => res.json().then(json => {
                    if (json.message === 'success') {
                        this.setState({ tags: [...this.state.tags, { id: json.id, text: tag }] },
                            () => { document.getElementById('tag').value = '' })
                    }
                }));
            // Ajouter au state
        }
        else {
            // Ajouter au state
            this.setState({ tags: [...this.state.tags, tagObject] },
                () => { document.getElementById('tag').value = '' })
        }
    }
    handleDelete(index) {
        console.log(index)
        const updatedTags = this.state.tags.filter((_, i) => i !== index);
        this.setState({ tags: updatedTags });
    };
    ////////// TAG MANAGEMENT //////////////

    submitForm(values, actions) {

        let errors = {};
        // let selectedArtist = [];

        // for (let artist of this.state.selectedArtist) {
        //     selectedArtist.push(artist.id);
        // }

        // values.artist = selectedArtist;

        // if (values.artists.length === 0 && values.newArtist === '') {
        //     errors.artist = 'Vous devez renseigner une catégorie.';
        // // }
        // if (parseInt(values.type) === 0 && values.wrongAnswers.match(/((?:.*\n){2,}(.+))/gm) === null) {
        //     errors.answer = 'Vous devez renseigner au moins 3 mauvaises réponses.';
        // }

        if (Object.keys(errors).length > 0) {
            this.setState({ errors: errors });
            return;
        } else {

            this.setState({ errors: null, status: 'submitting', imageError: false });
        }



        values.tags = [];
        for (let ttag of this.state.tags) {
            values.tags.push(ttag.id);
        }


        values.tag = undefined;

        console.log(values)
        const formData = new FormData();
        for (var value in values) {
            formData.append(value, values[value]);
        }

        const options = {
            method: 'POST',
            body: formData
        }
        fetch(this.state.serverUrl + 'painting', options)
            .then(res => res.json().then(json => {
                if (json.message === 'success') {
                    console.log('reseting', this.state.tags)
                    this.setState({ status: 'writing', imageName: null, tags: [] }, console.log('real end reseting', this.state.tags))
                    console.log('end reseting', this.state.tags)

                    actions.resetForm({
                        values: { title: '', title_alt: '', year: '', year_modifier: 0, year_end_period: '', location: '', url: '', artist: '', tags: '', tag: '' },
                    });
                    this.getData();
                } else if (json.message === 'error') {
                    if (json.error === 'not image') {
                        this.setState({ imageError: true, status: 'writing' })
                    }
                }
            }));
    }

    render() {

        const Artists = this.state.artists;
        const Location = this.state.locations;
        const ExistingTags = this.state.existingTags;
        const ExistingPaintings = this.state.existingPaintings;

        // console.log(Artists)
        // console.log(ExistingTags)
        // console.log(ExistingPaintings)
        console.log('rerender')

        return (
            <Formik
                initialValues={{
                    title: '', title_alt: '', artist: '', year: '', year_modifier: 0, year_end_period: '', location: '', url: '', nsfw: '', tags: '',
                }}

                validationSchema={
                    Yup.object({
                        title: Yup.string()
                            .min(1)
                            .required('Vous devez renseigner un titre')
                    })
                }
                onSubmit={async (values, actions) => {
                    this.submitForm(values, actions);
                }}>
                {({ errors, touched, values, setFieldValue }) => (
                    <Form className="section container pt-4" encType="multipart/form-data">
                        {/* IMAGE */}
                        <label htmlFor="image" className="label">Peinture</label>
                        {/* <ImageUploader
                            onFileAdded={(img) => this.getImageFileObject(img)}
                            onFileRemoved={(img) => this.runAfterImageDelete(img)}
                        /> */}
                        <div className="field is-grouped">

                            <div className="control">

                                <div className="file has-name">
                                    <label className="file-label">
                                        <input className="file-input" type="file" name="image" required
                                            onChange={(event) => {
                                                setFieldValue("image", event.currentTarget.files[0]);
                                                this.imageChanged(event.currentTarget.files[0]);
                                            }}
                                        />
                                        <span className="file-cta">
                                            <span className="file-icon">
                                                <i className="fas fa-upload"></i>
                                            </span>
                                            <span className="file-label">
                                                Choisir une image…
                                            </span>
                                        </span>
                                        {this.state.imageName ? <span className="file-name">
                                            {this.state.imageName}
                                        </span> : null}
                                    </label>
                                </div>

                            </div>
                        </div>
                        {this.state.imageError ?
                            <span className="help is-danger mb-5">Formats d'image valides : PNG, JPG, GIF, WEBP</span>
                            : null}





                        {/* TITLE */}
                        <div className="field">
                            <label htmlFor="title" className="label">Titre </label>
                            <div className="control has-icons-left">
                                <Field id="title" name="title" placeholder="Titre" className={errors.title && touched.title ? 'input is-danger' : 'input'} autoFocus min="1"
                                    onBlur={this.title} />
                                <span className="icon is-small is-left">
                                    <i className="fas fa-palette"></i>
                                </span>
                            </div>
                            <span className={errors.title && touched.title ? 'help is-danger' : 'help'}><ErrorMessage name="title" /></span>
                        </div>
                        <div className="field">
                            <div className="control has-icons-left">
                                <Field id="title_alt" name="title_alt" placeholder="Titre alternatif" className='input  is-small' autoFocus min="1" />
                                <span className="icon is-small is-left">
                                    <i className="fas fa-palette"></i>
                                </span>
                            </div>
                        </div>
                        {ExistingPaintings.length > 0 ?
                            <div className="field">
                                <span className="subtitle is-6">Tableaux similaires existantes :</span>
                                {
                                    ExistingPaintings.map(data => (
                                        <p key={data.id}>
                                            <span className="icon">
                                                <i className="fas fa-chevron-right"></i>
                                            </span>
                                            <span className="has-text-info has-background-info-light mr-3 is-italic">{data.painting}</span>
                                            <span className="has-text-success">{data.artist}</span>
                                        </p>
                                    ))
                                }
                            </div>
                            : null}



                        {/* ARTIST */}
                        <label htmlFor="artist" className="label">Artiste</label>
                        <div className="field">

                            <div className="control has-icons-left" >
                                <Field
                                    type="text"
                                    name="artist"
                                    list="artistList"
                                    id="artist"
                                    className="input"
                                    autoComplete="off"
                                />
                                <datalist id="artistList">
                                    {
                                        Artists !== undefined ?
                                            Artists.map(data => (
                                                <option value={data.fullname}>{data.fullname}</option>
                                            )) : null
                                    }
                                </datalist>
                                <span className="icon is-small is-left">
                                    <i className="fa fa-user"></i>
                                </span>
                            </div>
                        </div>



                        {/* YEAR */}
                        <div className="field">
                            <label htmlFor="year" className="label">Année </label>
                            <div className="control has-icons-left">
                                <Field id="year" name="year" placeholder="Année" className='input' autoFocus min="1"
                                    onBlur={this.year} />
                                <span className="icon is-small is-left">
                                    <i className="fas fa-calendar"></i>
                                </span>
                            </div>
                        </div>


                        <div className="field is-grouped">
                            <p className="control has-icons-left">
                                <span className="select">
                                    <Field as="select" id="year_modifier" name="year_modifier" defaultValue="0">
                                        <option value="0">Aucun modificateur</option>
                                        <option value="1">Circa</option>
                                        <option value="2">Before</option>
                                        <option value="3">After</option>
                                        <option value="4">Entre</option>
                                    </Field>
                                </span>
                                <span className="icon is-small is-left">
                                    <i className="fas fa-calendar"></i>
                                </span>
                            </p>
                            <div className="control has-icons-left">
                                <Field id="year_end_period" name="year_end_period" placeholder="Année de fin" className='input' autoFocus min="1" />
                                <span className="icon is-small is-left">
                                    <i className="fas fa-calendar"></i>
                                </span>
                            </div>
                        </div>
                        <div className="field">

                        </div>


                        {/* LOCALISATION */}
                        <label htmlFor="location" className="label">Localisation</label>
                        <div className="field">

                            <div className="control has-icons-left" >
                                <Field
                                    type="text"
                                    name="location"
                                    list="locationList"
                                    id="location"
                                    className="input"
                                    autoComplete="off"
                                />
                                <datalist id="locationList">
                                    {
                                        Location !== undefined ?
                                            Location.map(data => (
                                                <option value={data} key={data}>{data}</option>
                                            )) : null
                                    }
                                </datalist>
                                <span className="icon is-small is-left">
                                    <i className="fas fa-globe"></i>
                                </span>
                            </div>
                        </div>


                        {/* URL */}
                        <div className="field">
                            <label htmlFor="url" className="label">URL </label>
                            <div className="control has-icons-left">
                                <Field id="url" name="url" placeholder="URL" className='input' autoFocus min="1" />
                                <span className="icon is-small is-left">
                                    <i className="fas fa-link"></i>
                                </span>
                            </div>
                        </div>


                        {/* NSFW */}
                        <label htmlFor="nsfw" className="checkbox">
                            <Field id="nsfw" name="nsfw" type="checkbox" />
                            NSFW
                        </label>

                        {/* TAGS */}
                        <div className="field">
                            <label htmlFor="tags" className="label">Tags</label>
                            <div className="field is-grouped is-grouped-multiline">
                                {
                                    this.state.tags.map ? this.state.tags.map((tag, index) => (
                                        <div className="control" key={tag.id}>
                                            <div className="tags has-addons">
                                                <a className="tag">{tag.text}</a>
                                                <a className="tag is-delete" onClick={() => this.handleDelete(index)}></a>
                                            </div>
                                        </div>
                                    )) : <></>
                                }

                            </div>


                            <div className="field has-addons">
                                <div className="control has-icons-left">

                                    <Field id="tag" list="tagsList" name="tag" placeholder="impressionnisme,"
                                        autocomplete="off" className='input' min="1" />

                                    <datalist id="tagsList">
                                        {
                                            ExistingTags !== undefined ?
                                                ExistingTags.map(data => (
                                                    <option value={data.text}>{data.text}</option>
                                                )) : null
                                        }
                                    </datalist>
                                    <span className="icon is-small is-left">
                                        <i className="fas fa-link"></i>
                                    </span>
                                </div>
                                <div className="control">
                                    <span className="button is-primary"
                                        onClick={() => this.addTag(values)}
                                    >Ajouter</span>
                                </div>
                            </div>
                        </div>

                        <button type="submit" className={this.state.status === 'submitting' ? 'button is-primary is-loading' : 'button is-primary'}>Envoyer</button>
                    </Form>
                )
                }
            </Formik>
        )
    }
}

export default App;

import React, { Component } from "react";
import PaintingsList from "./PaintingsList"
import SideMenu from "./SideMenu"

export default function EditPainting() {
    return (
        <>
            thf
        </>
    )
}


// class BrowsePaintings extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//         }

//     }
// const { contacts } = useLoaderData();
//     render() {
//         return (
//             <section className="dashboard is-full-height">
//                 <nav className="dashboard-panel is-small p-0" style={{ flex: '0 0 0rem' }}>
//                     <SideMenu />
//                 </nav>
//                 <section className="dashboard-main is-scrollable p-0" style={{}}>
//                     <PaintingsList />
//                 </section>
//             </section>
//         )
//     }
// }
// }

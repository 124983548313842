import React, { Component } from "react";
import AddPainting from "./AddPainting"
import ViewPainting from "./ViewPainting"
import BrowsePaintings from "./BrowsePaintings"
// import Api from "./Api"
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";

class App extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    render() {
        return (
            <>
                <BrowserRouter>
                    <Routes>
                        <Route path="/add" element={<><AddPainting /></>} />
                        <Route path="/painting/:id" element={<><ViewPainting /></>} />
                        <Route path="/" element={<><BrowsePaintings /></>} />
                    </Routes>
                </BrowserRouter>
            </>
        )
    }
}

export default App;

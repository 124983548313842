import React, { Component } from "react";
import {
    Link
} from "react-router-dom";

class SideMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    render() {
        return (
            <aside className="menu has-background-text-dark has-text-white p-3" style={{ height: '100vh' }}>

                {/* <p className="menu-label">Peintures</p> */}
                {/* <Link to="/" relative="path" className="menu-list has-text-white">Toutes les peintures</Link> */}
                <Link to="/" relative="path" className="menu-list has-text-white">
                    <span className="icon is-medium">
                        <i className="mdi mdi-home mdi-24px"></i>
                    </span>
                </Link>
                <Link to="/" relative="path" className="menu-list has-text-white">
                    <span className="icon is-medium">
                        <i className="mdi mdi-rectangle-outline mdi-24px"></i>
                    </span>
                </Link>
                <Link to="/" relative="path" className="menu-list has-text-white">
                    <span className="icon is-medium">
                        <i className="mdi mdi-rectangle-outline mdi-24px mdi-rotate-90"></i>
                    </span>
                </Link>
                <Link to="/" relative="path" className="menu-list has-text-white">
                    <span className="icon is-medium">
                        <i className="mdi mdi-account-multiple mdi-24px"></i>
                    </span>
                </Link>
                <Link to="/" relative="path" className="menu-list has-text-white">
                    <span className="icon is-medium">
                        <i className="mdi mdi-bank mdi-24px"></i>
                    </span>
                </Link>
                <Link to="/" relative="path" className="menu-list has-text-white">
                    <span className="icon is-medium">
                        <i className="mdi mdi-tag mdi-24px"></i>
                    </span>
                </Link>

                {/* <p className="menu-label">Gestion</p> */}
                {/* <Link to="/add" relative="path" className="menu-list has-text-white">Ajouter une peinture</Link> */}
                <Link to="/add" relative="path" className="menu-list has-text-white">
                    <span className="icon is-medium mt-5">
                        <i className="mdi mdi-plus-box mdi-24px"></i>
                    </span>
                </Link>
            </aside>
        )
    }
}

export default SideMenu;

import React, { Component } from "react";
import Form from "./Form"
import Hero from "./Hero"
import SideMenu from "./SideMenu"

class AddPainting extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

    }

    render() {
        return (
            <section className="dashboard is-full-height">
                <nav className="dashboard-panel is-small p-0" style={{ flex: '0 0 0rem' }}>
                    <SideMenu />
                </nav>
                <section className="dashboard-main is-scrollable p-0" style={{}}>
                    <Hero title="Ajouter une œuvre" subtitle="à la collection" />
                    <Form />
                </section>
            </section>
        )
    }
}

export default AddPainting;

import React, { Component, useState, useEffect } from "react";
import {
    useParams
} from "react-router-dom";
import EditPainting from "./EditPainting"
import SideMenu from "./SideMenu"
import sanitize from "sanitize-filename";

import { CropperRef, Cropper } from 'react-advanced-cropper';
import 'react-advanced-cropper/dist/style.css'


import Lightbox from "yet-another-react-lightbox";
import Inline from "yet-another-react-lightbox/plugins/inline";

export default function ViewPainting({ }) {
    let { id } = useParams();
    const [serverUrl, setServerUrl] = useState('http://localhost:8080/');
    const [paintingRawInfo, setPaintingRawInfo] = useState(0);
    const [paintingInfo, setPaintingInfo] = useState({
        src: 'a',
        width: 0,
        height: 0,
        description: '',
    });


    const [open, setOpen] = React.useState(false);
    const [index, setIndex] = React.useState(0);

    const toggleOpen = (state) => () => setOpen(state);

    if (process.env.NODE_ENV === 'production') setServerUrl(window.location.protocol + '//' + window.location.host + '/');

    useEffect(() => {
        requestApi(id)
    }, []);

    function requestApi(value) {
        let urlSearchParams = { id: value };

        const params = new URLSearchParams(urlSearchParams)
        const options = {
            method: 'GET'
        }
        const requestUrl = serverUrl + 'paintings?' + params;
        fetch(requestUrl, options)
            .then((response) => response.json()
            )
            .then((json) => {
                setPaintingRawInfo({ results: json })

                if (json && json.length > 0) {
                    for (let result of json) {
                        const artist = result.artist ? result.artist + '__' : 'Anonymous__';
                        const year = result.year ? '__' + result.year : '';
                        const fileName = sanitize(artist + result.title + year);

                        setPaintingInfo({
                            src: serverUrl + 'large/' + fileName + '.jpg',
                            width: result.width,
                            height: result.height,
                            description: result.artist + ' - ' + result.title + ' (' + result.year + ')',
                        })
                    }
                }
            })
            .catch(err => console.log(err));
    }

    const onChange = (cropper) => { console.log(cropper.getCoordinates(), cropper.getCanvas()); };


    return (
        <section className="dashboard is-full-height">
            <nav className="dashboard-panel is-small p-0" style={{ flex: '0 0 0rem' }}>
                <SideMenu />
            </nav>
            <section className="dashboard-main is-scrollable p-0" style={{}}>

                <Cropper src={paintingInfo.src} onChange={onChange} className={'cropper'} />;


                <EditPainting />
            </section>
        </section>
    )
}
